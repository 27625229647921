import React, { useState } from 'react';
import { ITransaction, IFullTransaction, ILabel } from './types';

function Transaction({
  transaction,
  labels,
  saveChanges,
  deleteTransaction,
}: {
  transaction: IFullTransaction;
  labels: ILabel[];
  saveChanges: (newTransaction: ITransaction) => void;
  deleteTransaction: (id: number) => void;
}) {
  const [focused, setFocused] = useState<
    'amount' | 'description' | 'date' | 'icon' | null
  >(null);

  const [editDescriptionValue, setEditDescriptionValue] = useState(
    transaction.description
  );

  const saveDescription = () => {
    saveChanges({
      ...transaction,
      description: editDescriptionValue,
    });
    setFocused(null);
  };

  const [editAmountValue, setEditAmountValue] = useState(
    transaction.amount.toString()
  );

  const saveAmount = () => {
    const amount = parseFloat(editAmountValue) || 0;
    saveChanges({
      ...transaction,
      amount,
    });
    setEditAmountValue('' + amount);
    setFocused(null);
  };

  const [editDateValue, setEditDateValue] = useState(transaction.date);

  const saveDate = () => {
    setFocused(null);
    if (editDateValue === '') {
      setEditDateValue(transaction.date);
      return;
    }
    saveChanges({ ...transaction, date: editDateValue });
  };

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  const [editLabelValue, setEditLabelValue] = useState(transaction.label);

  return (
    <div className="flex gap-6">
      <div
        className={`${
          parseFloat(editAmountValue) >= 0 ? 'text-green-600' : 'text-red-600'
        } w-16`}
      >
        <input
          className="w-full bg-transparent text-end"
          value={
            focused === 'amount'
              ? editAmountValue
              : Math.abs(parseFloat(editAmountValue)).toFixed(2)
          }
          onChange={(e) => setEditAmountValue(e.target.value)}
          onFocus={() => setFocused('amount')}
          onBlur={saveAmount}
          onKeyDown={onEnter}
        />
      </div>
      <div className="text-x relative">
        {labels.find((label) => label.name === editLabelValue)?.icon}
        <select
          className="bg-transparent absolute top-0 left-0 w-full h-full opacity-0"
          value={editLabelValue}
          onChange={(e) => setEditLabelValue(e.target.value)}
          onBlur={() => {
            saveChanges({ ...transaction, label: editLabelValue });
            setFocused(null);
          }}
        >
          {labels.map((label) => (
            <option key={label.name} value={label.name}>
              {label.icon} {label.name}
            </option>
          ))}
        </select>
      </div>{' '}
      <div className="grow">
        <input
          className="bg-transparent"
          value={editDescriptionValue}
          onChange={(e) => setEditDescriptionValue(e.target.value)}
          onFocus={() => setFocused('description')}
          onBlur={saveDescription}
          onKeyDown={onEnter}
        />
      </div>
      <div>
        <input
          type="date"
          className="bg-transparent"
          value={editDateValue}
          onChange={(e) => setEditDateValue(e.target.value)}
          onFocus={() => setFocused('date')}
          onBlur={saveDate}
          onKeyDown={onEnter}
        />
      </div>
      <button
        className="text-gray-300 hover:text-red-600"
        onClick={() => {
          deleteTransaction(transaction.id);
        }}
      >
        x
      </button>
    </div>
  );
}

export { Transaction };
