import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';

const element = document.getElementById('root');

if (!element) {
  throw new Error("Could not find element with id 'root'");
}

const root = createRoot(element);

root.render(
  <GoogleOAuthProvider clientId="748429608453-4ql916778o5pb12nk8ig86ac1hrbp6sv.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>
);
