import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { IFullTransaction, ILabel, ITransaction } from './types';
import { useContext, useEffect } from 'react';
import { UserContext } from './UserContextProvider';
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { useNavigate } from 'react-router-dom';

export const getKey = (date: string) => {
  const ddate = new Date(date);
  const month = ddate.getMonth();
  const year = ddate.getFullYear();
  return `${year} ${month} `;
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({ baseURL: BASE_URL });

export const AxiosInterceptor = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const addBearerToken = (config: InternalAxiosRequestConfig) => {
      const token = user?.access_token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    };
    const resInterceptor = (response: AxiosResponse) => response;
    const errInterceptor = (error: AxiosError) => {
      if (error.response?.status === 401) {
        setUser(null);
        navigate('/login');
      }
      return Promise.reject(error);
    };
    const reqInterceptor = (config: InternalAxiosRequestConfig) => {
      return addBearerToken(config);
    };
    const reqInterceptorId = api.interceptors.request.use(reqInterceptor);
    const resInterceptorId = api.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => {
      api.interceptors.request.eject(reqInterceptorId);
      api.interceptors.response.eject(resInterceptorId);
    };
  }, [user, setUser, navigate]);

  return children;
};

export const useNewTransactionMutation = (
  queryClient: QueryClient,
  onSuccessParam = () => {}
) => {
  return useMutation({
    mutationFn: async (transaction: Omit<ITransaction, 'id'>) =>
      api.post('/transactions', transaction),
    onSuccess: () => {
      onSuccessParam();
      queryClient.invalidateQueries({ queryKey: ['transactions'] });
    },
  });
};

export const useSaveChangesMutation = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: async (newTransaction: ITransaction) =>
      api.post(`/transactions/${newTransaction.id}`, newTransaction),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['transactions'] });
    },
  });
};

export const useDeleteTransactionMutation = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: async (id: number) => api.delete(`/transactions/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['transactions'] });
    },
  });
};

export const useTransactionsQuery = () => {
  return useQuery({
    queryKey: ['transactions'],
    queryFn: async () =>
      api
        .get('/transactions')
        .then((resJson) => resJson.data.data as Promise<IFullTransaction[]>)
        .then((transactions) =>
          transactions.reduce(
            (acc, transaction) => {
              const key = getKey(transaction.date);
              if (!acc[key]) {
                acc[key] = [transaction];
              } else {
                acc[key].push(transaction);
              }
              return acc;
            },
            {} as { [key: string]: IFullTransaction[] }
          )
        ),
  });
};

export const useLabelsQuery = () => {
  return useQuery({
    queryKey: ['labels'],
    queryFn: async () =>
      api.get('/labels').then((res) => res.data.data as Promise<ILabel[]>),
  });
};

export const usePostLabelMutation = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async (label: ILabel) => api.post('/labels', label),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['labels'] });
    },
  });
