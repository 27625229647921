import React, { useState } from 'react';
import { LabelsModal } from './LabelsModal';
import { PieChart } from './PieChart';
import { List } from './List';
import { UserContext } from './UserContextProvider';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { GoogleUserInfo } from './types';
import { AxiosInterceptor } from './api';

const fetchProfile = (access_token: string) =>
  fetch(
    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`
  ).then((res) => res.json() as Promise<GoogleUserInfo>);

const Home = () => {
  const [user] = React.useContext(UserContext);
  if (!user) {
    return <Navigate to="/login" />;
  }

  const { data: profile } = useQuery({
    queryKey: ['profile', user.access_token],
    // @ts-ignore : user is defined because of the enabled prop
    queryFn: async () => fetchProfile(user.access_token),
  });

  const [open, setOpen] = useState(false);
  return (
    <AxiosInterceptor>
      <span className="text-midday absolute top-2 left-2">
        Bonjour {profile?.given_name}
        {profile?.name === 'Constance Masson' && ' ❤️'} !
      </span>
      <div className="flex w-full flex-col-reverse md:flex-row">
        <PieChart />
        <List />
      </div>
      <LabelsModal open={open} setOpen={setOpen} />
      <button
        className="text-midday flex absolute top-2 md:bottom-2 right-2 h-fit"
        onClick={() => setOpen(true)}
      >
        Catégories
      </button>
    </AxiosInterceptor>
  );
};

export { Home };
