import React, { useEffect, useState } from 'react';
import { useTransactionsQuery, getKey } from './api';
import { Chart } from 'react-google-charts';

const PieChart = () => {
  const { data: transactions, isSuccess, isLoading } = useTransactionsQuery();
  const [data, setData] = useState<[string, string | number][]>([
    ['Label', 'Amount'],
  ]);
  const [spendings, setSpendings] = useState(0);
  const key = getKey(new Date().toDateString());
  useEffect(() => {
    if (isSuccess) {
      const monthTransaction = transactions[key] || [];
      const dataDict = monthTransaction.reduce(
        (acc, transaction) => {
          const label = transaction.label;
          if (transaction.amount > 0) return acc;
          if (acc[label]) {
            acc[label] += Math.abs(transaction.amount);
          } else {
            acc[label] = Math.abs(transaction.amount);
          }
          setSpendings((spendings) => spendings + Math.abs(transaction.amount));
          return acc;
        },
        {} as { [key: string]: number }
      );
      Object.entries(dataDict).forEach(([label, amount]) => {
        setData((data) => [...data, [label, amount]]);
      });
    }
    return () => {
      setData([['Label', 'Amount']]);
      setSpendings(0);
    };
  }, [isSuccess, transactions]);

  const options = {
    backgroundColor: 'transparent',
    legend: {
      position: 'bottom',
      alignment: 'center',
      textStyle: { color: 'white' },
    },
    chartArea: { width: '100%', height: '80%' },
    pieSliceText: 'label',
    pieSliceTextStyle: { color: 'white' },
  };

  return (
    <div className="basis-1/2 m-auto">
      {isLoading ? (
        <div className="text-white flex justify-center">Chargement...</div>
      ) : data.length > 1 ? (
        <div className="flex flex-col items-center">
          <span className="text-red-300 font-title text-5xl">
            {spendings.toLocaleString()}
            <span className="text-sun text-sm pl-2">€</span>
          </span>
          <Chart
            chartType="PieChart"
            data={data}
            options={options}
            height={'600px'}
          />
        </div>
      ) : (
        <div className="text-white flex justify-center">Aucune dépense</div>
      )}
    </div>
  );
};

export { PieChart };
