import React, { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Transaction } from './Transaction';
import {
  useDeleteTransactionMutation,
  useLabelsQuery,
  useNewTransactionMutation,
  useSaveChangesMutation,
  useTransactionsQuery,
} from './api';

const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

const List = () => {
  const queryClient = useQueryClient();
  const scrollToTop = () => {
    const transactionsWrapper = document.getElementById('transactions-wrapper');
    if (transactionsWrapper) {
      transactionsWrapper.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  const { mutate: mutateNew } = useNewTransactionMutation(
    queryClient,
    scrollToTop
  );
  const { mutate } = useSaveChangesMutation(queryClient);
  const { mutate: mutateDelete } = useDeleteTransactionMutation(queryClient);
  const { data: transactionsDict, isLoading } = useTransactionsQuery();
  const { data: labels } = useLabelsQuery();

  const [sum, setSum] = React.useState(0);

  useEffect(() => {
    if (transactionsDict) {
      let sum = 0;
      Object.keys(transactionsDict).forEach((key) => {
        transactionsDict[key].forEach((transaction) => {
          sum += transaction.amount;
        });
      });
      setSum(Math.round(sum * 100) / 100);
    }
  }, [transactionsDict]);

  return (
    <div className="p-4 pb-8 basis-1/2">
      {isLoading || transactionsDict === undefined ? (
        <div className="flex justify-around align-middle h-full">
          <div className="text-white m-auto">Chargement...</div>
        </div>
      ) : (
        <>
          <h1 className="text-midday text-center font-title text-8xl h-1/3 flex items-center justify-center">
            {sum.toLocaleString()}
            <span className="text-3xl text-sun">€</span>
          </h1>
          {Object.keys(transactionsDict).length ? (
            <div
              id="transactions-wrapper"
              className="bg-cloud p-4 pt-0 rounded-3xl relative overflow-scroll h-2/3"
            >
              {Object.keys(transactionsDict)
                .sort()
                .reverse()
                .map((yearMonth, index) => {
                  const [year, month] = yearMonth
                    .split(' ')
                    .map((s) => parseInt(s));
                  const moisAnnee = `${months[month]} ${year}`;
                  return (
                    <div key={moisAnnee} id={moisAnnee}>
                      <div className="flex flex-row justify-between sticky top-0 bg-cloud p-3 pt-7 z-10">
                        <h3 className="text-midday font-title text-3xl">
                          {moisAnnee}
                        </h3>
                        {index == 0 && (
                          <button
                            onClick={() =>
                              mutateNew({
                                amount: 10,
                                description: 'Nouvelle transaction',
                                date: new Date().toISOString().split('T')[0],
                                label: 'Courses',
                              })
                            }
                            className="text-cloud bg-sun text-4xl flex rounded-full justify-center items-center w-10 h-10"
                          >
                            +
                          </button>
                        )}
                      </div>
                      {transactionsDict[yearMonth]
                        .sort((t1, t2) => {
                          const dateDifference =
                            new Date(t1.date).getTime() -
                            new Date(t2.date).getTime();
                          if (dateDifference === 0) {
                            return t1.id - t2.id;
                          } else {
                            return dateDifference;
                          }
                        })
                        .reverse()
                        .map((transaction) => (
                          <Transaction
                            key={transaction.id}
                            transaction={transaction}
                            labels={labels || []}
                            saveChanges={mutate}
                            deleteTransaction={mutateDelete}
                          />
                        ))}
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="flex justify-around">
              <button
                onClick={() =>
                  mutateNew({
                    amount: 10,
                    description: 'Nouvelle transaction',
                    date: new Date().toISOString().split('T')[0],
                    label: 'Courses',
                  })
                }
                className="text-cloud bg-sun text-4xl flex rounded-full justify-center items-center w-10 h-10"
              >
                +
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { List };
