import React, { useEffect, useRef, useState } from 'react';
import { useLabelsQuery, usePostLabelMutation } from './api';
import { QueryClient } from '@tanstack/react-query';

const LabelsModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const queryClient = new QueryClient();
  const { data: labels } = useLabelsQuery();
  const { mutate } = usePostLabelMutation(queryClient);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [labelName, setLabelName] = useState('');
  const [labelIcon, setLabelIcon] = useState('');
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate({ name: labelName, icon: labelIcon });
    setLabelName('');
    setLabelIcon('');
    setIsFormOpen(false);
  };

  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
        setIsFormOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  return (
    <div
      className={`${open ? 'flex' : 'hidden'}
        fixed top-0 left-0 w-full h-full bg-midnight bg-opacity-50 justify-center items-center z-20
      `}
    >
      <div ref={modalRef} className="bg-cloud p-4 rounded-3xl relative w-96">
        <h1 className="text-midday font-title text-3xl">Labels</h1>
        <div className="flex flex-col gap-2">
          {labels?.map((label) => (
            <div key={label.name} className="flex flex-row gap-2 items-center">
              {label.icon}
              <span>{label.name}</span>
            </div>
          ))}
        </div>
        {isFormOpen ? (
          <form onSubmit={handleSubmit} className="flex gap-2 h-10 p-2">
            <input
              type="text"
              value={labelIcon}
              className="text-2xl w-6 bg-transparent"
              onChange={(e) => {
                const isEmoji =
                  /^[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F018}-\u{1F270}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6C5}\u{1F30D}-\u{1F567}]+$/u.test(
                    e.target.value
                  );
                if (isEmoji || e.target.value === '') {
                  setLabelIcon(e.target.value);
                }
              }}
              placeholder=":)"
              required
            />
            <input
              type="text"
              value={labelName}
              className="bg-transparent mr-auto"
              onChange={(e) => setLabelName(e.target.value.replace(' ', '-'))}
              placeholder="Nom"
              required
            />
            <button type="submit">✅</button>
            <button onClick={() => setIsFormOpen(false)}>❌</button>
          </form>
        ) : (
          <button
            className="h-10 float-right"
            onClick={() => setIsFormOpen(true)}
          >
            ➕
          </button>
        )}
      </div>
    </div>
  );
};

export { LabelsModal };
