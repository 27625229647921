import { TokenResponse } from '@react-oauth/google';
import React, { createContext, useState } from 'react';

export const UserContext = createContext<
  [TokenResponse | null, (user: TokenResponse | null) => void]
>([null, () => {}]);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUserInternal] = useState<TokenResponse | null>(() => {
    const token = localStorage.getItem('token');
    if (token) {
      return JSON.parse(token);
    }
    return null;
  });

  const setUser = (user: TokenResponse | null) => {
    setUserInternal(user);
    if (user) {
      localStorage.setItem('token', JSON.stringify(user));
    } else {
      localStorage.removeItem('token');
    }
  };

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};
