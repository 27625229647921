import { useGoogleLogin } from '@react-oauth/google';
import React, { useContext } from 'react';
import { UserContext } from './UserContextProvider';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const [, setUser] = useContext(UserContext);
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setUser(tokenResponse);
      navigate('/');
    },
    onError(errorResponse) {
      console.error(errorResponse);
    },
  });

  return (
    <div className="flex flex-col gap-6 p-6 bg-cloud rounded-3xl items-center m-auto">
      <h1 className="text-midday font-title text-3xl">PitBank</h1>
      <p>Connectez vous pour accéder au site !</p>
      <button
        className="bg-sun text-midnight rounded-lg p-4"
        onClick={() => login()}
      >
        Connexion
      </button>
    </div>
  );
};

export { Auth };
