import React, { useEffect } from 'react';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Auth } from './Auth';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home } from './Home';
import { UserProvider } from './UserContextProvider';

const queryClient = new QueryClient();
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/login',
    element: <Auth />,
  },
]);

const App = () => {
  const devMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  useEffect(() => {
    if (devMode) document.title = 'PitBank - LOCAL';
  }, [devMode]);
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <div
          className={`${
            devMode ? 'bg-blue-950' : 'bg-midnight'
          } min-h-screen md:h-screen flex`}
        >
          <RouterProvider router={router} />
        </div>
      </UserProvider>
    </QueryClientProvider>
  );
};

export { App };
